
<template>
  <div :id="id" />
</template>

<script>
  import WangEditor from 'wangeditor'
  import createKityFormula from './kityformula'
  import {getCookie} from "../../../assets/js/cookie";
  import {changeStr, objKeySort, pinjie} from "../../../assets/js/sign";
import Md5 from 'md5'
  export default {
    name: 'WEditor',
    props: {
      // 编辑器id，主要用于页面中有多个编辑器时使用
      id: {
        type: String,
        default: 'editor'
      },
      // 编辑器高度
      height: {
        type: Number,
        default: 300
      },
      // 编辑器显示层级
      zIndex: {
        type: Number,
        default: 0
      },
      // 是否显示公式菜单
      showFormula: {
        type: Boolean,
        default: true
      },
      // 是否显示全屏菜单
      showFullScreen: {
        type: Boolean,
        default: true
      },
      // 编辑器回显内容
      content: {
        type: String,
        default: ''
      },
      // 编辑器菜单
      menus: {
        type: Array,
        default: function() {
          return [
            'head', // 标题
            'bold', // 加粗
            'fontSize', // 字号
            'fontName', // 字体
            'italic', // 斜体
            'underline', // 下划线
            'strikeThrough', // 删除线
            'indent', // 缩进
            'lineHeight', // 行高
            'foreColor', // 字体颜色
            'backColor', // 背景色
            'link', // 链接
            'list', // 序列
            'todo', // 待办事项
            'justify', // 对齐
            'quote', // 引用
            'image', // 图片
            'code', // 代码
            'splitLine', // 分割线
            'undo', // 撤销
            'redo' // 恢复
          ]
        }
      }
    },
    data() {
      return {
        editor: null,
        editorData: '',
        old:'',
        new:'',
        uuid0:[],
        uuid1:[]
      }
    },

    mounted() {
      this.createEditor()
    },
    methods: {
      createEditor(){
        const editor = new WangEditor('#' + this.id)
        // 配置菜单
        editor.config.menus = this.menus
        // 配置高度
        editor.config.height = this.height
        // 如果需要公式
        if (this.showFormula) {
          const { PanelMenu, Panel } = WangEditor
          class kityFormula extends PanelMenu {
            // 公式输入插件
            constructor(editors) {
              const $elem = WangEditor.$(
                      `<div class="w-e-menu"><i class="iconfont icon-gongshi" style="font-size:18px;"></i></div>`
              )
              super($elem, editors)
            }
            // 菜单点击事件
            clickHandler() {
              const conf = createKityFormula(editor)
              const panel = new Panel(this, conf)
              panel.create()
            }
            // 必要，不要删除
            tryChangeActive() {}
          }
          // 注册公式菜单
          const kityFormulaKey = 'kityformulaKey' // 菜单 key ，各个菜单不能重复
          editor.menus.extend('kityformulaKey', kityFormula)
          if (this.showFormula && this.menus.length === 1) {
            editor.config.menus.unshift(kityFormulaKey)
          } else {
            editor.config.menus = editor.config.menus.concat(kityFormulaKey)
          }
          editor.config.showFullScreen = this.showFullScreen
        }
        // 配置自定义上传图片
        editor.config.uploadFileName = 'file'
        editor.config.uploadImgServer = '/api/uploader/v1/upload_file'
        editor.config.uploadImgHeaders = {'Uxb-User-Agent':'webVersion:v1.0.0'}
        editor.config.uploadImgAccept = []
        editor.config.uploadImgParams = {
          bucket_type:1,
          siteid:getCookie('siteid'),
          up_config:1,
          uploadfiletype:1,
          operation_mode:1,
          stime:parseInt(new Date().getTime() / 1000)
        }
        editor.config.uploadImgParams.sign =''
                editor.config.uploadImgParams.sign = Md5(
                changeStr(pinjie(objKeySort(editor.config.uploadImgParams)))
        )
        editor.config.uploadImgHooks = {
          fail: function(){
            this.$message.error('上传文件失败')
          },
          error: function(){
            this.$message.error('上传文件失败')
          },

          // 把上传的图片上传到编辑器
          customInsert: function(insertImgFn, result) {
            if (result.code === 0) {
              insertImgFn(result.data.inline_url)
            }
          }
        }
        // 隐藏网络图功能
        editor.config.showLinkImg = false
        // 配置 onchange 回调函数，将数据同步到 vue 中
        editor.config.onchange = newHtml => {
          this.editorData = newHtml
          // 如果需要执行事件
          this.$listeners['change'] ? this.$emit('change', this.editorData) : null;
          this.onchange(newHtml);
        }
        editor.config.zIndex = this.zIndex
        // editor.config.onchange = (html)=>{this.editorData=html;this.onchange(html);}
        if (this.content) {
          this.$nextTick(() => {
            editor.txt.html(this.content)
          })
        }
        // 创建编辑器
        editor.create()
        this.editor = editor
      },
      onchange(html){
        var reg=/[a-z0-9-]{36}/g;
        var str=html.match(reg)
        console.log(html,str)
        this.old=this.new;
        this.new=str;
         console.log("old" ,this.old!=null)
        console.log("new" ,this.new)
        this.$parent.uuid0 = this.new
        if(this.old&&this.old.length>this.new.length){
          // name :that.old[this.old.length-1]})  删除的项
          this.$parent.uuid1.push(this.old[this.old.length-1])
        }
      }
    },
    beforeDestroy() {
      // 销毁编辑器
      this.editor.destroy()
      this.editor = null
    }
  }
</script>

<style>
  .w-e-toolbar {
    position: unset !important;
  }
</style>
