// import { getToken } from '@/utils/auth'
import axios from 'axios'
export default function(editor) {
    // panel 中需要用到的id
    const inputIFrameId = 'kityformula_' + Math.ceil(Math.random() * 10)
    const btnOkId = 'kityformula-btn' + Math.ceil(Math.random() * 10)
    return {
        width: 650,
        height: 460,
        // panel 中可包含多个 tab
        tabs: [
            {
                // tab 的标题
                title: editor.i18next.t('menus.panelMenus.formula.插入公式'),
                // 模板
                tpl: `<div>
                    <iframe id="${inputIFrameId}" class="iframe" height="400px" width="100%" frameborder="0" scrolling="no" src="/student/kityformula/index.html"></iframe>
                        <div class="w-e-button-container">
                        <button id="${btnOkId}" class="right">
                            ${editor.i18next.t('确认插入')}
                        </button>
                    </div>
                </div>`,
                // 事件绑定
                events: [
                    {
                        selector: '#' + btnOkId,
                        type: 'click',
                        fn: () => {
                            // 执行插入公式
                            const node = document.getElementById(inputIFrameId)
                            const kfe = node.contentWindow.kfe
                            kfe.execCommand('get.image.data', function(data) {
                                const postData = {
                                    base64Code: data.img,
                                    bucket_type:1,
                                    uploadfiletype:2,
                                    up_config:1,
                                    operation_mode:1
                                }
                                axios.post('/api/uploader/v1/upload_file', postData,{ headers:{
                                'Uxb-User-Agent': 'webVersion:v1.0.0'}}).then(({ data: res }) => {
                                    if (res.code === 0) {
                                        editor.txt.append(
                                            '<img class="formula" src="' + res.data.inline_url + '"  alt="" />'
                                        )
                                        document.getElementsByClassName('w-e-panel-container')[0].style.display = 'none'
                                    }
                                })
                            })
                        }
                    }
                ]
            } // tab end
        ] // tabs end
    }
}
